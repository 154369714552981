<template>
  <div class="">
    <nav class="dash-header navbar navbar-expand-sm">
      <div
        class="flex justify-between w-full items-center py-1 custom-wrapper dash-header-in sc-desktop"
      >
        <div class="app-brand py-2">
          <router-link
            exact
            to="/dashboard"
            class="flex gap-x-2 items-center w-full"
          >
            <!-- <img src="@/assets/icons/img/icon-tea.png" height="20" width="50"> -->
            <span>WAAS Build</span>
          </router-link>
        </div>

        <div class="nav-wrap">
          <ul class="user-dash-nav navbar-nav flex items-center">
            <li class="nav-item dropdown">
              <div class="dropdown relative">
                <a
                  class="dropdown-toggle font-medium text-xs leading-tight uppercase rounded transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="profile_img"
                    width="30"
                    height="30"
                    class="rounded-circle h-10 w-10"
                  />
                </a>
                <ul
                  class="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <router-link class="dropdown-item" to="/profile"
                      ><span>Profile</span></router-link
                    >
                    <a class="dropdown-item logout" href="#" @click="logout()"
                      >Log Out</a
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="dash-header-in sc-mobile w-full">
        <div class="flex justify-between w-full">
          <div>
            <div id="hamburger-m">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div class="pt-2 nav-not">...</div>
        </div>
      </div>
    </nav>

    <div class="bar-placeholder" id="bar-placeholder"></div>
    <div class="user-dash-nav s-bar z-50">
      <ul>
        <li>
          <router-link :to="'/dashboard'"> <span>Dashboard</span></router-link>
        </li>
        <li>
          <router-link to="/wallets"> <span>Wallets</span></router-link>
        </li>
        <li>
          <router-link to="/merchants"> <span>Merchants</span></router-link>
        </li>
        <li>
          <router-link to="/ledger-accounts">
            <span>Ledger Accounts</span></router-link
          >
        </li>
        <li>
          <router-link to="/transactions"
            ><span>Transactions</span></router-link
          >
        </li>
        <li>
          <router-link to="/settings"><span>Setting</span></router-link>
        </li>
        <li class="logout">
          <a href="#" @click="logout()"><span>Logout</span></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  created() {

  },
  data() {
    return {
      profile_img: this.$cookies.get("user_dp"),
      total: this.$cookies.get("note"),
    };
  },
  methods: {
    logout() {
      dialog.success("You've been logged out");
      this.$cookies.remove("token");
      this.$cookies.remove("user_dp");
      this.$cookies.remove("loggedIn");
      this.$cookies.remove("complete_reg");
      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 500);

      // var token = this.$cookies.get("token");
      // dialog.loading();
      // $http
      //   .post("/logout", null, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((response) => {
      //     dialog.success("You've been logged out");
      //     this.$cookies.remove("token");
      //     this.$cookies.remove("user_dp");
      //     this.$cookies.remove("loggedIn");
      //     this.$cookies.remove("complete_reg");
      //     setTimeout(() => {
      //       this.$router.push({ name: "login" });
      //     }, 500);
      //   })
      //   .catch((error) => {})
      //   .finally(() => {});
    }
  },
  watch: {
    $route(to, from) {
      this.profile_img = this.$cookies.get("user_dp");
    },
  },
};
</script>
