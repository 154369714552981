import $ from 'jquery'

$(document).ready(function (e) {
    // var imagesSel = document.querySelector("#p-images");
    // if (imagesSel != null) {
    //   imagesSel.addEventListener("change", function(e) {
    //     var elm = $(e.target)
    //     var files = e.target.files;
    //     elm.parents('.image-wrap').find('.im-box').hide();
    //     for (let v = 0; v < files.length; v++) {
    //         readURL(files[v],v,elm);
    //     }
    //   });
    // }
    
    $('#app').on('change','#p-images',function(e) {
        var elm = $(e.target)
        var files = e.target.files;
        elm.parents('.image-wrap').find('.im-box').hide();
        for (let v = 0; v < files.length; v++) {
            readURL(files[v],v,elm);
        }
    })

    // $('#app').on('click','.imgs',function(e) {
    //     var elm = $(this)
    //     var p = elm.parents('.image-wrap').find('.property-images')
    //     $(p).val('')
    //     var fe = $(p).trigger('click');
    //     $(fe).on('change',function (e) {
    //         var elm = $(this)
    //         var files = e.target.files;
    //         for (let v = 0; v < files.length; v++) {
    //             readURL(files[v],v,elm);
    //         }
    //         elm.parents('.image-wrap').find('.im-box').hide();
    //         $(fe).off('change');
    //     })
    // })

    $('#app').on('click', '.remv', function (e) {
        var pp = $('.property-images')
        var dl = $(this).data('num');
        var rm = $('.property-images').attr('data-removed')
        var rmArr = [];
        rmArr = rm.split(',')
        if (rm == '') {
          rmArr = []
        }
        rmArr.push(dl.toString())
        $('.property-images').attr('data-removed',rmArr.join(','))
        var pa = $(this).parent();
        var dl = $(this).data('num');
        var dv = $('#del_thumbnail').val();
        if (dv == '') {
            $('#del_thumbnail').val(dv += dl);
        }else {
            $('#del_thumbnail').val(dv += "." + dl);
        }
        pa.fadeOut();
    })

    $(window).on('click',function (e) {
        if ($('#multi-list-wrap').css('display') == 'block' && e.target.id != 'p-details') {
            $('#multi-list-wrap').hide()
        }
    })

    $('#app').on('input','.cost_price_alt',function (e) {
        let input = $($(this).find('input'))
        let cost = $('.cost_price')
        console.log(input)
        if (input.val() != '') {
            var n = commify(input.val())
            console.log(n)
            input.val(n)
        }else{
            $('.cost_price').val('')
        }
    })

    $('#app').on('click','#p-details',function (e) {
        var ml = $('#multi-list');
        $('#multi-list-wrap').show();
    })

    $('#app').on('input','#p-details',function (e) {
        var ml = $('#multi-list li');
        var q = $(this).val()

        $http.get("/elastic-search/"+q)
            .then((response) => {
                var res = response.data.data
                var lists = '';
                res.forEach(li => {
                    lists += '<li>'+li+'</li>'
                });
                $('#multi-list').html(lists)
              })
              .catch((error) => {})
              .finally(() => {});
    })

    $('#app').on('click','#multi-list li',function (e) {
        var selected = $(this).html()
        $('#p-details').val('')
        $('#multi-selected').append(pSelected(selected))
    })

    $('#app').on('click','.si-rmv',function () {
        var c = $(this).parents('.s-item')
        rSelected(c.find('.content').attr('data-text'))
        $(this).parents('.s-item').remove()
    })

    $('#app').on('click','#add-detail',function () {
        var val = $(this).parent().find('#p-details').val()
        pSelected(val)
        $(this).parent().find('#p-details').val('')
    })

    // $('#app').on('input','#com_input',function (e) {
    //     var input = parseInt($(this).val())
    //     var n = Number((input).toFixed(1)).toLocaleString()
    //     $(this).val(n)
    //     console.log(n)
    // })

    $(window).on('click',function (e) {
        if (e.target.id == 'bar-placeholder') {
            toggleBar()
        }
    })

    $('#app').on('click','#hamburger-m',function (e) {
        toggleBar()
    })
})

function readURL(file,index,el) {
    var elh = el.parents('.image-wrap').find('.property-images-holder');
    $(elh).html('')
    var fileReader = new FileReader();
    if (file.type.match('image')) {
        fileReader.onload = function () {
            var elm = htm(fileReader.result,index);
            elh.append(elm);
        };
        fileReader.readAsDataURL(file);
        return true;
    }
}

function htm(src,ind) {
    return `<div class="imgBlc">
        <div class="fa fa-times-circle remv" data-num="${ind}">X</div>
        <span class="t-up">
        </span>
        <img class="measureImg" src="${src}" data-ind="${ind}">
    </div>`;
}

function pSelected(content) {
    var exist = true;
    var data = $('#p-data').val()
    const dataArray = data.split(",");
    if (!dataArray.includes(content)) {
        dataArray.push(content)
        exist = false
    }
    var cont = dataArray.filter((v) => v)
    let text = cont.join();
    $('#p-data').val(text)
    if (content != '') {
        var htm = `<div class="s-item mb-2 mr-2">
                <div class="d-flex">
                    <div class="content" data-text="${content}">
                        ${content}
                    </div>
                    <div class="si-rmv">
                        X
                    </div>
                </div>
            </div>`;
        if (!exist) {
            $('#multi-selected').append(htm)
        }
    }
    $('#multi-list-wrap').hide()
}

function rSelected(content) {
    // alert(content.replace(/[^a-zA-Z ]/g, ""))
    var exist = true;
    var nData = new Array()
    var data = $('#p-data').val()
    const dataArray = data.split(",");
    for (let index = 0; index < dataArray.length; index++) {
        const element = dataArray[index];
        if (content === element) {
            continue;
        }
        nData.push(element)
    }
    $('#p-data').val(nData.join())
}

function commify(number) {
   var num = parseInt(number.split(',').join(''))
   $('.cost_price').val(num)
   var n = Number((num).toFixed(1)).toLocaleString()
   return n;
}

function toggleBar() {
    $('.bar-placeholder').toggle()
    $('.s-bar').toggle()
    $('.nav-links').toggle()
}
