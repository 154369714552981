<template>
	<div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto px-3" :id="modal" aria-labelledby="exampleModalLgLabel" aria-modal="true" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg relative w-auto pointer-events-none">
			<div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current py-14 px-6 lg:px-16">
			<div class="modal-header flex flex-shrink-0 items-center justify-between mb-8">
				<h5 class="lg:text-2xl text-lg font-medium leading-normal text-gray-800" id="exampleModalLgLabel">
					{{title}}
				</h5>
				<button type="button"
				class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
				data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body relative">
				<slot/>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '../../../../components/atoms/button/Buton'
	export default {
		name: 'Modal',
		props:{
			modal: String,
			title: String
		}
	}

	export const ModalOpen = {
		props:{
			title: [String,NodeList],
			modal: String,
      type: String
		},
		components:{
			Button
		},
		template: `
			<Button
			data-bs-toggle="modal"
			:data-bs-target="'#'+modal"
			:title="title"
      :type="type"/>
		`
	};
</script>
