<template>
  <DashboardLayout>
    <Tile>
      <slot />
    </Tile>
  </DashboardLayout>
</template>

<script>
import Tile from "../../components/atoms/tile/Tile";
import DashboardLayout from "../../components/layout/DashboardLayout";

export default {
  components: {
    Tile,
    DashboardLayout,
  },
};
</script>
