<template>
    <div class="mt-20">
        <slot/>
    </div>
</template>

<script>
   export default {
    created() {
      this.loading = true;
    }
   }
</script>
