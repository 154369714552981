import { AxiosResponse } from "axios"
import Swal from "sweetalert2"
import iconUrl from "./icon"
import { icons } from "./icons"


export class dialog { 
    
    constructor() {
        const icon = new icons()
    }
    static loading() {
        var ic = new icons()
        Swal.fire({
            html:ic.loader(44,44),
            showConfirmButton:false,
            showCancelButton:false,
            allowOutsideClick: false
        })        
    }

    static error(response) {
        var icon = new icons()
        if(Array.isArray(response.data.message)) {
            var emsg = '';
            var errors = response.data.message
            console.log(errors.length)
            for (let index = 0; index < errors.length; index++) {
                const element = errors[index];
                emsg += `<div class="alert dark alert-danger p-1" role="alert">
                    ${element}
                </div>`;
            }
            Swal.fire({
                html: emsg,
                showCancelButton: true,
                showConfirmButton: false,
                icon:'error',
                cancelButtonText:'close',
                cancelButtonColor:'var(--color-error)'
            })
        }else {
            Swal.fire({
                text: response.data.message,
                showCancelButton: true,
                showConfirmButton: false,
                icon:'error',
                cancelButtonText:'close',
                cancelButtonColor:'var(--color-error)'
            })
        }
    }

    static errorTxt(text) {
        Swal.fire({
            text: text,
            showCancelButton: true,
            showConfirmButton: false,
            icon:'error',
            cancelButtonText:'close',
            cancelButtonColor:'var(--color-error)',
        })
    }

    static success(message) {
        Swal.fire({
            text: message,
            showCancelButton: true,
            showConfirmButton: false,
            icon:'success',
            cancelButtonText:'close',
            cancelButtonColor:'var(--color-primary)'
        })
    }

    static close() {
        Swal.close()      
    }
}

// export const waitLoader = () => {
//     var icon = new icons()
//     var loader = Swal.fire({
//         html:icon.loader(44,44),
//         showConfirmButton:false,
//         showCancelButton:false,
//     })

//     // Swal.close()
// }