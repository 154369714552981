<template>
  <PrimaryLayout>
    <Tile class="max-w-xl mx-auto lg:px-20 p-6 py-10">
      <form method="POST" @submit.prevent="signIn($event)">
        <div class="mt-10">
          <Input name="email" type="text" label="Email" />
        </div>

        <div class="mt-4">
          <Input name="password" type="password" label="Password" required />
        </div>

        <div class="flex justify-between items-center mt-4">
          <div class="flex items-center">
            <Checkbox id="remember_me" />
            <label for="remember_me" class="ml-1">Remember me</label>
          </div>
          <div class="field-check">
            <router-link to="/forgot-password" style="color: red"
              >forgot password?</router-link
            >
          </div>
        </div>

        <div class="form-group mt-10 text-center">
          <Button title="Log In" name="login" type="submit" />
        </div>

        <!-- <div class="field mt-2">
          <div class="">
            <div class="flex justify-center text-center">
              <span>Are you a new user?</span>
              <span
                ><router-link to="/sign-up" class="ml-2 text-main"
                  >Sign up</router-link
                ></span
              >
            </div>
          </div>
        </div> -->
      </form>
    </Tile>
  </PrimaryLayout>
</template>

<script>
import Tile from "../../../components/atoms/tile/Tile";
import Input from "../../../components/atoms/input/Input";
import Checkbox from "../../../components/atoms/input/Checkbox";
import Button from "../../../components/atoms/button/Buton";
import PrimaryLayout from "../../../components/layout/PrimaryLayout";

export default {
  name: "Login",
  components: {
    Tile,
    Input,
    Checkbox,
    Button,
    PrimaryLayout,
  },
  data() {
    return {
      isProcessing: false,
      btnIcon: "btn",
      user: [],
      api_keys: [],
      preference: [],
      wallets: [],
      mode: "live",
      error_msg_padding_tp: 0,
      business_logo: "",
      webhook_url: "yolo",
    };
  },
  created() {
    if (this.$cookies.get("token") != undefined) {
      // this.$router.push("/dashboard");
    }
  },
  methods: {
    signIn(event) {
      dialog.loading();
      this.isProcessing = true;
      var form = event.target;
      var formData = new FormData(form);
      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });
      $http_auth
        .post("/login", data)
        .then((response) => {
          var user = response.data;
          dialog.success("Welcome!");
          this.$cookies.set("token", user.token);
          this.$cookies.set("loggedIn", true);
          this.$cookies.set("user", user.user);
          this.$router.push("/dashboard");

          // var token = this.$cookies.get("token");
          // console.log(token);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            dialog.errorTxt(error.response.data.msg);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
