<template>
    <div id="input" >
        <div v-if="label" class="mb-1">
            <label :for="id" class="capitalize">{{label}}</label>
        </div>
        <div class="flex">
            <div v-if="variant" class="bg-gray-200 flex items-center px-3 rounded-l-md">
                <span>{{groupedIcon}}</span>
            </div>
            <input 
                id="input" 
                :name="name" 
                class="bg-gray-100 rounded-lg w-full h-12 px-2.5 outline-none" 
                v-bind:class="!variant? 'rounded-md' : 'rounded-md rounded-l-none'" 
                :type="type" 
                v-bind="$attrs"
                v-on:input="$emit('input', $event.target.value)"/>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props:{
        id: String,
        name: String,
        type: String,
        label: String,
        variant: Boolean,
        groupedIcon: String
    },
    // model: {
    //     prop: 'hidden',
    //     event: 'blur'
    // },
    data() {
        return {
            // mvalue: this.value
        };
    },
    // methods: {
    //     handleInput (value) {
    //         this.$emit('blur', value)
    //     }
    // }
}
</script>