<template>
  <DashboardLayout class="">
    <div class="grid grid-cols-3 gap-x-10">
      <AsyncLayout class="stat-bar mb-10 max-w-lg" :loading="loading">
        <div>
          <div class="box-shadow py-4 h-44">
            <div class="mt-8 text-white text-center">Total Wallets</div>
            <div class="h3 text-center text-white font-weight-bold">
              {{ stats.total_wallets }}
            </div>
          </div>
        </div>
      </AsyncLayout>

      <AsyncLayout class="stat-bar mb-10 max-w-lg" :loading="loading">
        <div>
          <div class="box-shadow py-4 h-44">
            <div class="mt-8 text-white text-center">Availabel Wallets</div>
            <div class="h3 text-center text-white font-weight-bold">
              {{ stats.total_available_wallets }}
            </div>
          </div>
        </div>
      </AsyncLayout>
    </div>

    <div class="lg:max-w-lg">
      <div class="grid grid-cols-2 gap-x-10 wallet-action">
        <div
          class="flex rounded text-white items-center bg-main-alt-light py-3 px-2"
          data-bs-toggle="modal"
          data-bs-target="#generate-wallet"
        >
          <div style="color: rgb(74, 129, 102)" class="ml-5">
            Generate Wallets
          </div>
        </div>
      </div>
    </div>

    <div class="my-10">
      <div class="box-shadow">
        <Header title="Wallets" />
        <AsyncLayout :loading="loading" class="shadow-none">
          <div class="table-responsive">
            <table class="table m-table w-full">
              <thead>
                <tr>
                  <th>Wallet</th>
                  <th>Network</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor: pointer"
                  v-for="(wallet, i) in wallets"
                  :key="i"
                >
                  <td>{{ wallet.wallet }}</td>
                  <td>{{ wallet.chain }}</td>
                  <td>{{ wallet.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-4">
            <Pagination :pages="pages" />
          </div>
        </AsyncLayout>
      </div>
    </div>

    <Modal modal="generate-wallet" title="Generate Wallets">
      <form
        method="post"
        enctype="multipart/form-data"
        autocomplete="off"
        @submit.prevent="generateWallets($event)"
      >
        <div class="m-form">
          <div class="mb-20">
            <div class="d-lg-flex">
              <div class="col-lg-6 p-0">
                <label class="form-control-label" style="font-weight: 600"
                  >Number of wallets</label
                >
              </div>
              <div class="col-lg-6 p-0">
                <Input type="text" min="1" name="number_of_wallets" required />
                <!-- <input type="hidden" class="form-control cost_price" /> -->
              </div>
            </div>
          </div>

          <!-- <div class="mb-20">
            <div class="d-lg-flex items-center">
              <div class="col-lg-6 p-0">
                <label class="form-control-label" style="font-weight: 600"
                  >Pin</label
                >
              </div>
              <div class="col-lg-6 p-0">
                <Input name="pin" type="password" min="1" max="4" required />
              </div>
            </div>
          </div> -->
        </div>

        <div class="text-center mt-12 w-44 mx-auto">
          <Button title="Generate Wallets" />
        </div>
      </form>
    </Modal>
  </DashboardLayout>
</template>

<script>
import Header from "../../../components/composed/PageTitle/Header";
import Modal, {
  ModalOpen,
} from "../../../components/composed/Modals/Modal/Modal";
import Pagination from "../../../components/composed/Pagination/Pagination";
import AsyncLayout from "../../../components/layout/AsyncLayout";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import Input from "../../../components/atoms/input/Input";
import Button from "../../../components/atoms/button/Buton";
import { addCommaToNumber } from "../../../utils/util";
import SelectInput from "../../../components/atoms/input/SelectInput";

export default {
  components: {
    Modal,
    ModalOpen,
    Pagination,
    Header,
    AsyncLayout,
    DashboardLayout,
    Input,
    Button,
    SelectInput,
  },
  created() {
    this.getWallets();
    this.getData();
  },
  data() {
    return {
      wallets: [],
      walletHistory: [],
      total_amount: 0,
      pages: [],
      loading: true,
      stats: {},
    };
  },
  computed: {
    ref: function () {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  watch: {},
  methods: {
    getWallets() {
      var token = this.$cookies.get("token");
      $http
        .get("/get-all-wallets", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.wallets = response.data.data;
          // this.pages = response.data.page;
          this.loading = false;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getData() {
      var token = this.$cookies.get("token");
      $http
        .get("/stats", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.stats = response.data.data;
          this.loading = false;
        })
        .catch((error) => {})
        .finally(() => {
          setTimeout(() => {
            dialog.close();
          }, 500);
        });
    },
    generateWallets(event) {
      dialog.loading()
      var token = this.$cookies.get("token");
      var form = event.target;
      var formData = new FormData(form);
      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });
      $http
        .post("/generate-wallets", data, {headers: { Authorization: `Bearer ${token}` }})
        .then((response) => {
          setTimeout(() => {
            dialog.success(response.data.msg);
          }, 1500);
          window.location.reload()
        })
        .catch((error) => {
          if (error.response) {
            dialog.errorTxt(error.response.data.msg);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
