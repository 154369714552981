<template>
    <!-- <button class="bg-main w-full py-2 rounded text-white">
        {{title}}
    </button> -->
    <button class="py-2 rounded " :class="type === 'other'? 'border-2 border-main rounded text-main' : 'bg-main text-white'" v-html="title"  
    v-on:click="$emit('click', $event.target.value)"/>
</template>

<script>
export default {
    props:{
        title: [String,NodeList],
        type: String
    }
}
</script>

<style scoped>
    button {
        width: 100%;
    }
</style>