<template>
    <Tile v-if="loading" class="preload-wrap relative">
        <div class="preload flex justify-center items-center h-full">
            <div class="text-black" v-html="icons.loader(44,44)"/>
        </div>
    </Tile>
    <div v-else>
        <slot/>
    </div>
</template>

<script>
    import { icons } from "../../utils/icons"
    import Tile from '../../components/atoms/tile/Tile'
    export default {
        components:{
            Tile
        },
        data() {
            return {
                icons:new icons(),
            }
        },
        props:{
            loading: Boolean
        }
    }
</script>

<style scoped>
    .preload-wrap {
        height: 150px;
    }

    .preload {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .preload > div {
        position: absolute;
        display: flex;
    }

</style>