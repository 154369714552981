import Dashboard from '../pages/app/dashboard'
import Merchants from '../pages/app/merchants'
import Transactions from '../pages/app/transactions'
import Login from '../pages/auth/login'
import SignUp from '../pages/auth/signup'
import ForgotPassword from '../pages/auth/forgot-password'
import Wallet from '../pages/app/wallet'
import Settings from '../pages/app/settings'
import LedgerAccounts from "../pages/app/merchants/LedgerAccounts"

import { createRouter, createWebHistory } from 'vue-router'

export default{
  // history: createWebHistory(),
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/sign-in',
      component: Login
    },
    {
      path: '/sign-up/:ref?',
      component: SignUp
    },
    {
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/wallets',
      component: Wallet
    },
    {
      path: '/merchants',
      component: Merchants
    },
    {
      path: '/transactions',
      component: Transactions
    },
    {
      path: '/settings',
      component: Settings
    },
    {
      path: '/forgot-password',
      component: ForgotPassword
    },
    {
      path: '/ledger-accounts',
      component: LedgerAccounts
    }
  ]
}
