<template>
<PrimaryLayout>
    <Tile class="max-w-xl mx-auto lg:px-20 p-6 py-10">
        <form method="POST" @submit.prevent="signIn($event)">
            <input type="hidden" name="referred_by" v-model="ref_code" required>

            <div class="mt-4">
                <Input name="email" type="email" label="Email" required placeholder="Email"/>
            </div>

            <div class="mt-4">
                <Input name="phone" type="text" label="Phone Number" required placeholder="Phone Number"/>
            </div>

            <div class="mt-4">
                <Input name="password" type="password" label="Password" required placeholder="Password"/>
            </div>

            <div class="mt-4">
                <Input name="confirmPassword" type="password" label="Confirm Password" required placeholder="Confirm Password"/>
            </div>

            <div class="form-group mt-10 text-center">
                <Button title="Sign Up" type="submit"/>
            </div>

            
            <div class="flex justify-center mt-2">
                <span>Already have an account?</span>
                <span><router-link to="/sign-in" class="text-main ml-2">Sign in</router-link></span>
            </div>
        </form>
    </Tile>
  </PrimaryLayout>
</template>

<script>
import Tile from '../../../components/atoms/tile/Tile'
import Input from '../../../components/atoms/input/Input'
import Checkbox from '../../../components/atoms/input/Checkbox'
import Button from '../../../components/atoms/button/Buton'
import PrimaryLayout from '../../../components/layout/PrimaryLayout'


export default {
  name: "Sign Up",
  props: ['ref_code'],
  components: {
    Tile,
    Input,
    Checkbox,
    Button,
    PrimaryLayout
  },
  mounted () {

  },
  data () {
    return {
      // icons: new icons,
      isProcessing: false,
      btnIcon: 'btn',
      user: [],
      api_keys: [],
      preference: [],
      wallets: [],
      mode: 'live',
      error_msg_padding_tp: 0,
      business_logo: '',
      webhook_url: 'yolo'
    }
  },
  created () {
    // alert(this.ref_code)
  },
  methods: {
    signIn (event) {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      var form = event.target
      var formData = new FormData(form)
      dialog.loading()
      $http.post('/register', formData)
        .then((response) => {
          this.$cookies.set('token', response.data.token)
          dialog.success('Your account has been created successfully, a link has been sent to your email to verify your account')
          setTimeout(() => {
            dialog.close()
            this.$router.push('/complete-registration')
          }, 500);
        }).catch((error) => {
          if (error.response) {
            dialog.error(error.response)
          }
        }).finally(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>
