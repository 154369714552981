<template>
   <nav v-if="pages.has_pages">
        <ul class="pagination nav-pn">
            <li class="page-item disabled" v-if="pages.cur_page == 1">
                <a class="page-link text-gray-600" href="#" aria-label="Previous">
                    Prev
                </a>
            </li>
            <li class="page-item" v-else>
                <!-- <a class="page-link" href="" aria-label="Previous">
                    Prev
                </a> -->

                <router-link :to="url+'?page='+ (pages.cur_page - 1)" class="page-link text-gray-900">
                    Prev
                </router-link>
            </li>

            <li class="page-item " :class="(i == pages.cur_page) ? 'active' : ''" v-for="i in pages.last_page" :key="i">
                <router-link :to="url+'?page='+i" class="page-link text-gray-900" v-if="i != pages.cur_page">{{i}}</router-link>
                <span class="page-link" v-else>{{i}}</span>
            </li>

            <!-- @foreach ($elements as $element)
                {{-- "Three Dots" Separator --}}
                @if (is_string($element))
                    <li class="page-item disabled" ><a class="page-link">{{ $element }}</a></li>
                @endif

                {{-- Array Of Links --}}
                @if (is_array($element))
                    @foreach ($element as $page => $url)
                        @if ($page == $paginator->currentPage())
                            <li class="page-item active"><a class="page-link">{{ $page }}</a></li>
                        @else
                            <li class="page-item"><a class="page-link" href="{{ $url }}">{{ $page }}</a></li>
                        @endif
                    @endforeach
                @endif
            @endforeach -->

            <!-- {{-- Next Page Link --}} -->
            <!-- @if ($paginator->hasMorePages()) -->
                <li class="page-item" v-if="pages.cur_page != pages.last_page">
                    <!-- <a class="page-link" href="" aria-label="Next">
                        Next
                    </a> -->
                    <router-link :to="url+'?page='+ (pages.cur_page + 1)" class="page-link text-gray-900">
                        Next
                    </router-link>
                </li>
            <!-- @else -->
                <li class="page-item disabled" v-else>
                    <a class="page-link text-gray-600" href="" aria-label="Next">
                        Next
                    </a>
                </li>
            <!-- @endif -->
        </ul>
    </nav>
</template>

<script>
    export default {
        props: ["pages"],
        data() {
            return {
               
            };
        },
        created() {

        },
        computed: {
            url: function (i) {
                return this.$route.path;
            }
        }
    }
</script>

<style scoped>
    .nav-pn .router-link-active {
        background-color: #fff;
        color: #757575 !important;
    }

    .page-item.active .page-link {
        z-index: 2;
        color: #fff;
        background-color: #3ded97;
        border-color: #3ded97;
    }
</style>