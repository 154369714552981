<template>
  <div id="app" class="customwrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
#app {
  margin-top: 0px;
}

.custom-wrapper,
.custom-big-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0.8rem 2rem;
}

@media screen and (max-width: 600px) {
  .custom-wrapper,
  .m-wrapper {
    margin: 0 auto;
    width: 100%;
    padding: 0 1.2rem;
  }

  .m-wrapper-2 {
    margin: 0 auto;
  }

  .title-underline-green {
    background: linear-gradient(var(--color-primary), var(--color-primary)) bottom/100%
        18px no-repeat,
      transparent;
  }
}

@media screen and (max-width: 400px) {
  .custom-wrapper,
  .m-wrapper {
    margin: 0 auto;
  }

  .m-wrapper-2 {
    margin: 0 auto;
  }
}
</style>
