<template>
    <div class="">
        <div v-if="label" class="mb-1">
            <label :for="id" class="capitalize">{{label}}</label>
        </div>
        <div>
            <select class="
                form-select 
                appearance-none
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                bg-clip-padding bg-no-repeat
                border border-solid
                rounded
                transition
                ease-in-out
                m-0
                text-black
                focus:outline-none
                h-11" 
                aria-label="Default select example"
                @change="onChange($event)"
                v-bind="$attrs"
                >
                <slot/>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectInput",
    inheritAttrs: false,
    props:{
        id: String,
        onChange: Function,
        label: String
    },
    methods: {
        // onChange(event) {
            
        // }
    }
}
</script>