<template>
  <ProfileLayout>
    <AsyncLayout :loading="loading" class="shadow-none">
      <Header title="Settings" />
      <form
        method="post"
        enctype="multipart/form-data"
        autocomplete="off"
        class="w-full"
        @submit.prevent="updateSettings($event)"
      >
        <div class="row">
          <div class="mb-10 col-lg-6">
            <Input
              label="BSC Contract Address"
              type="text"
              name="bsc_contract_addr"
              v-model="settings.bsc_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="BSC Token Address"
              type="text"
              name="bsc_token_addr"
              v-model="settings.bsc_token_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="bsc_token_addr_private_key"
              type="text"
              name="bsc_token_addr_private_key"
              v-model="settings.bsc_token_addr_private_key"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="polygon_contract_addr"
              type="text"
              name="polygon_contract_addr"
              v-model="settings.polygon_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="polygon_token_addr"
              name="polygon_token_addr"
              type="text"
              v-model="settings.polygon_token_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="polygon_token_addr_private_key"
              name="polygon_token_addr_private_key"
              type="text"
              v-model="settings.polygon_token_addr_private_key"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="busd_token_contract_addr"
              name="busd_token_contract_addr"
              type="text"
              v-model="settings.busd_token_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="usdc_token_contract_addr"
              name="usdc_token_contract_addr"
              type="text"
              v-model="settings.usdc_token_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="polygon_token_addr_private_key"
              name="polygon_token_addr_private_key"
              type="text"
              v-model="settings.polygon_token_addr_private_key"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="wbnb_token_contract_addr"
              name="wbnb_token_contract_addr"
              type="text"
              v-model="settings.wbnb_token_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="mock_cake_token_contract_addr"
              name="mock_cake_token_contract_addr"
              type="text"
              v-model="settings.mock_cake_token_contract_addr"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="busd_buy_fee"
              name="busd_buy_fee"
              type="text"
              v-model="settings.busd_buy_fee"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="busd_sell_fee"
              name="busd_sell_fee"
              type="text"
              v-model="settings.busd_sell_fee"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="usdc_sell_fee"
              name="usdc_sell_fee"
              type="text"
              v-model="settings.usdc_sell_fee"
            />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="usdc_buy_fee"
              name="usdc_buy_fee"
              type="text"
              v-model="settings.usdc_buy_fee"
            />
          </div>

          
        </div>
        <div class="mb-10 w-40">
          <Button title="Save" />
        </div>
      </form>

      <h3 class="font-bold text-xl mt-10">Change Password</h3>
      <form
        method="post"
        autocomplete="off"
        class="w-full my-1"
        @submit.prevent="updatePassword($event)"
      >
        <div class="row">
          <div class="mb-10 col-lg-6">
            <Input label="Old Password" name="old_password" type="password" />
          </div>

          <div class="mb-10 col-lg-6">
            <Input label="New Password" name="new_password" type="password" />
          </div>

          <div class="mb-10 col-lg-6">
            <Input
              label="Confirm Password"
              name="confirm_new_password"
              type="password"
            />
          </div>
        </div>

        <div class="mb-10 w-40">
          <Button title="Save" />
        </div>
      </form>

      <h3 class="font-bold text-xl mt-10">Set Pin</h3>
      <form
        method="post"
        autocomplete="off"
        class="w-full my-1"
        @submit.prevent="setPin($event)"
      >
        <div class="row">
          <div class="mb-10 col-lg-6">
            <Input label="Pin" name="pin" type="password" />
          </div>
        </div>
        <div class="mb-10 w-40">
          <Button title="Set Pin" />
        </div>
      </form>
    </AsyncLayout>
  </ProfileLayout>
</template>

<script>
import ProfileLayout from "../../../components/layout/ProfileLayout";
import Button from "../../../components/atoms/button/Buton";
import Input from "../../../components/atoms/input/Input";
import SelectInput from "../../../components/atoms/input/SelectInput";
import Header from "../../../components/composed/PageTitle/Header";
import AsyncLayout from "../../../components/layout/AsyncLayout";

export default {
  components: {
    Button,
    Input,
    SelectInput,
    ProfileLayout,
    Header,
    AsyncLayout,
  },
  mounted() {},
  data() {
    return {
      user: {},
      settings: {},
      loading: true,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.loading = true;
      var token = this.$cookies.get("token");
      $http
        .get("/settings", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          this.settings = response.data.data;
          this.pages = response.data.page;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    updateSettings(e) {
      dialog.loading()
      var token = this.$cookies.get("token");
      var form = e.target;
      var formData = new FormData(form);
      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });
      $http
        .post("/update-settings", data, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          dialog.success("Success");
        })
        .catch((error) => {})
        .finally(() => {
          // this.loading = false;
        });
    },
    updatePassword(e) {
      
    },
    setPin(event) {
      
    },
  },
};
</script>
