<template>
  <DashboardLayout class="b-section col-lg-12">
    <Tile>
      <Header title="Transactions" />
      <div v-if="!loading">
        <div v-if="transactions.length > 0">
          <div class="table-responsive" style="overflow-x: auto">
            <table class="table w-full table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Token</th>
                  <th scope="col">Status</th>
                  <th scope="col">View Transaction</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in transactions" :key="transaction.id">
                  <td class="text-nowrap">{{ transaction.id }}</td>
                  <td class="text-nowrap">
                    {{ transaction.amount }}
                  </td>
                  <td>
                    <span>{{ transaction.token }}</span>
                  </td>
                  <td class="text-nowrap">
                    <span>{{ transaction.status }}</span>
                  </td>
                  <td class="text-nowrap">
                    <span><a :href="'https://testnet.bscscan.com/tx/'+transaction.transaction_hash" target="_blank">View Tranx</a></span>
                  </td>
                  <td class="text-nowrap">
                    <span>{{ transaction.createdAt }}</span>
                  </td>
                  <td class="text-nowrap">
                    <span>--</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-4">
            <Pagination :pages="pages" />
          </div>
        </div>
        <div v-else>
          <div class="mt-4 h4">No Transaction Found</div>
        </div>
      </div>
      <AsyncLayout :loading="loading" class="shadow-none"></AsyncLayout>
    </Tile>
  </DashboardLayout>
</template>

<script>
import Pagination from "../../../components/composed/Pagination/Pagination";
import Header from "../../../components/composed/PageTitle/Header";
import AsyncLayout from "../../../components/layout/AsyncLayout";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import Tile from "../../../components/atoms/tile/Tile";

export default {
  components: {
    Pagination,
    Header,
    AsyncLayout,
    DashboardLayout,
    Tile,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      page: 1,
      transactions: [],
      isProcessing: false,
      total: 0,
      pages: [],
      loading: true,
    };
  },
  watch: {
    $route(to, from) {
      const params = new URLSearchParams(this.$route.query);
      this.getData(params.toString());
    },
  },
  methods: {
    getData() {
      var token = this.$cookies.get("token");
      $http
        .get("/transactions", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          this.transactions = response.data.data;
          this.pages = response.data.page;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    next(event) {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;
      let el = event.target;
      this.page++;
      this.getData(el);
    },
  },
};
</script>
