<template>
  <PrimaryLayout>
      <Tile class="max-w-lg mx-auto p-6 py-10">
          <form v-if="page == 1" method="POST" @submit.prevent="requestToken($event)">
              <div class="mt-10">
                <Input name="email" v-model="email" type="email" label="Email" required/>
              </div>

              <div class="form-group mt-10 text-center">
                <Button title="Proceed" type="submit"/>
              </div>

              <div class="flex justify-center mt-3">
                  <span><router-link to="/sign-in" class="text-main ml-2">Sign in</router-link></span>
              </div>
          </form>

          <form v-if="page == 2" method="POST" @submit.prevent="verifyToken($event)">
              <div class="bg-yellow-700 rounded-lg py-1.5 px-6 mb-3 text-base text-blue-700 inline-flex items-center w-full" role="alert">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                </svg>
                Enter the token sent to your email
              </div>

              <Input name="email" v-model="email" type="hidden"/>

              <div class="mt-6">
                <Input name="token" v-model="token" type="text" label="Token" required/>
              </div>

              <div class="form-group mt-10 text-center">
                <Button title="Proceed" type="submit"/>
              </div>

              <div class="flex justify-center mt-3">
                  <span><router-link to="/sign-in" class="text-main ml-2">Sign in</router-link></span>
              </div>
          </form>

          <form v-if="page == 3" method="POST" @submit.prevent="changePassword($event)">
              <div class="bg-yellow-700 rounded-lg py-1.5 px-6 mb-3 text-base text-blue-700 inline-flex items-center w-full" role="alert">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                </svg>
                Create your new password
              </div>

              <Input name="email" v-model="email" type="hidden"/>
              <Input name="token" v-model="token" type="hidden"/>

              <div class="mt-6">
                <Input name="newPassword" type="password" label="New Password" required/>
              </div>

              <div class="mt-8">
                <Input name="confirmNewPassword" type="password" label="Confirm New Password" required/>
              </div>

              <div class="form-group mt-10 text-center">
                <Button title="Proceed" type="submit"/>
              </div>

              <div class="flex justify-center mt-3">
                  <span><router-link to="/sign-in" class="text-main ml-2">Sign in</router-link></span>
              </div>
          </form>
      </Tile>
  </PrimaryLayout>
</template>

<script>
import Tile from '../../../components/atoms/tile/Tile'
import Input from '../../../components/atoms/input/Input'
import Button from '../../../components/atoms/button/Buton'
import PrimaryLayout from '../../../components/layout/PrimaryLayout'

export default {
  name: 'Login',
  components: {
    Tile,
    Input,
    Button,
    PrimaryLayout
  },
  data () {
    return {
      page:1,
      email:'',
      token:''
    }
  },
  created () {
    // alert(this.$cookies.get("token"))
  },
  methods: {
    requestToken (event) {
      dialog.loading()
      this.isProcessing = true
      var form = event.target
      var formData = new FormData(form)
      $http.post('/forgot-password', formData)
        .then((response) => {
            // dialog.success('A reset token has been sent to your email')
            this.page = 2
            dialog.close()
        }).catch((error) => {
          if (error.response) {
            dialog.error(error.response)
          }
        }).finally(() => {

        })
    },

    verifyToken (event) {
      dialog.loading()
      this.isProcessing = true
      var form = event.target
      var formData = new FormData(form)
      $http.post('/verify-token', formData)
        .then((response) => {
            this.page = 3
            dialog.close()
        }).catch((error) => {
          if (error.response) {
            dialog.error(error.response)
          }
        }).finally(() => {

        })
    },

    changePassword (event) {
      dialog.loading()
      this.isProcessing = true
      var form = event.target
      var formData = new FormData(form)
      $http.post('/change-password', formData)
        .then((response) => {
            dialog.success('Your password has been changed successfully')
        }).catch((error) => {
          if (error.response) {
            dialog.error(error.response)
          }
        }).finally(() => {

        })
    }
  }
}
</script>
