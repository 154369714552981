import loader from "../assets/icons/svgs/loader.svg";

const iconUrl = (iconName) => {
  let logo1 = '';
  switch (iconName) {
    case "loader":
      return loader;
    default:
      return logo1;
  }
};

export default iconUrl;
