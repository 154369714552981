<template>
    <header 
        class="mb-4 text-xl font-bold text-gray-800"
    >
    {{title}}</header>
</template>

<script>
export default {
    props:{
        title: String
    }
}
</script>