<template>
  <div>
    <input :id="id" type="checkbox" :name="name" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    name: String,
  },
};
</script>
