import { createApp } from "vue";
import App from "./App.vue";
import router from "./scripts/router";
// import 'bootstrap/dist/js/bootstrap'
// import 'bootstrap/assets/styles/core.scss';
// import 'bootstrap'
import "./styles/app.scss";
import "../index.css";
import "./utils/request";
import "./utils/util";
import {http,http_auth} from "./utils/request";
import { dialog } from "./utils/dialog";
import Vue from "vue";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueCookies from "vue-cookies-reactive";
import VueRouter from "vue-router";
import "tw-elements";
import VCalendar from "v-calendar";
import VueCompositionAPI from "@vue/composition-api";
import Flutterwave from "flutterwave-vue-v3";
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faAngleLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);
library.add(faAngleLeft);
library.add(faTrash);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Flutterwave, {
  publicKey: "FLWPUBK-307efe48c0282ed47478a9e2a14b527c-X",
});

Vue.config.productionTip = false;

window.$cookies = VueCookies;
// Vue.use(VueCookie);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(Donut);
Vue.use(VueSweetalert2);

// import VueCookies from 'vue3-cookies'
// import Donut from 'vue-css-donut-chart';
// import 'vue-css-donut-chart/dist/vcdonut.css';
// import 'v-calendar/dist/style.css';

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.use(VueCompositionAPI);
window.currency = "₦";
window.$http = http;
window.$http_auth = http_auth;
window.dialog = dialog;

const routes = new VueRouter(router);
const app = new Vue({
  el: "#app",
  router: routes,
  render: (h) => h(App),
});
Vue.use(app);

$("#app").on("click", ".reset-filter", function (e) {
  app.$router.push(app.$route.path);
});

import "./scripts/custom";

// const app = createApp(App)
//   .use(router)
//   .use(VueCookies)
//   // .use(Donut)

// app.use(VCalendar, {})
// app.use(VueCookies, {
//     expireTimes: "30d",
//     path: "/",
//     domain: "",
//     secure: true,
//     sameSite: "None"
// });

// app.mount('#app')
