<template>
  <div class="">
    <NavBar />
    <!-- <div class="sidebar bg-main fixed h-full">
      <div>
        <ul>
          <li>Dashboard</li>
          <li>Wallets</li>
          <li>Merchants</li>
        </ul>
      </div>
    </div> -->
    <div class="mt-24 main">
      <slot />
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/composed/NavBar/Navbar";
export default {
  components: {
    NavBar,
  },
  mounted() {
    this.auth();
  },
  created() {},
  methods: {
    auth() {
      var token = this.$cookies.get("token");
      console.log(token)
      $http
        .get("/authenticate", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          let user = response.data;
          this.$cookies.set("user", user.data);
          this.$cookies.set("balance", user.wallet_balance);
          this.$cookies.set("user_dp", user.data.profile_img);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$cookies.remove("token");
            this.$cookies.remove("user_dp");
            this.$cookies.remove("loggedIn");
            // this.$cookies.remove("complete_reg");
            this.$router.push({ name: "login" });
            dialog.errorTxt("Your session has expired");
          }
        })
        .finally(() => {});
    },
  },
};
</script>
