<template>
  <DashboardLayout class="pane">
    <div class="flex row mb-16 gap-y-4">
      <div class="col-lg-4 mb-2">
        <AsyncLayout
          :loading="loading"
          class="box-shadow text-center py-4 h-44"
        >
          <div class="mt-8">Total Merchants</div>
          <div class="h3 mt-2">{{ stats.total_users }}</div>
        </AsyncLayout>
      </div>

      <div class="col-lg-4 mb-2">
        <AsyncLayout
          :loading="loading"
          class="box-shadow text-center py-4 h-44"
        >
          <div class="mt-8">Total Wallets</div>
          <div class="h3 mt-2">{{ stats.total_wallets }}</div>
        </AsyncLayout>
      </div>

      <div class="col-lg-4 mb-2">
        <AsyncLayout
          :loading="loading"
          class="box-shadow text-center py-4 h-44"
        >
          <div class="mt-8">Available Wallets</div>
          <div class="h3 mt-2">{{ stats.total_available_wallets }}</div>
        </AsyncLayout>
      </div>
    </div>

    <div class="mt-10">
      <Header title="Recent Transactions" />
      <div v-if="!loading" class="w-full gap-12 flex-wrap">
        <div class="table-responsive" style="overflow-x: auto">
          <table class="table w-full table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Amount</th>
                <th scope="col">Token</th>
                <th scope="col">Status</th>
                <th scope="col">View Transaction</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in transactions" :key="transaction.id">
                <td class="text-nowrap">{{ transaction.id }}</td>
                <td class="text-nowrap">
                  {{ transaction.amount }}
                </td>
                <td>
                  <span>{{ transaction.token }}</span>
                </td>
                <td class="text-nowrap">
                  <span>{{ transaction.status }}</span>
                </td>
                <td class="text-nowrap">
                  <span
                    ><a
                      :href="
                        'https://testnet.bscscan.com/tx/' +
                        transaction.transaction_hash
                      "
                      target="_blank"
                      >View Tranx</a
                    ></span
                  >
                </td>
                <td class="text-nowrap">
                  <span>{{ transaction.createdAt }}</span>
                </td>
                <td class="text-nowrap">
                  <span>--</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else class="w-full flex-wrap">
        <AsyncLayout
          :loading="loading"
          :key="i"
          class="h-full mb-4"
        ></AsyncLayout>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import Header from "../../../components/composed/PageTitle/Header";
import AsyncLayout from "../../../components/layout/AsyncLayout";
import DashboardLayout from "../../../components/layout/DashboardLayout";

export default {
  components: {
    Header,
    AsyncLayout,
    DashboardLayout,
  },
  data() {
    return {
      loading: true,
      userData: "",
      balance: "",
      transactions: [],
      activities: [],
      properties: [],
      trending: [],
      portfolio: [],
      portfolio_stats: [],
      newp: [],
      stats: {},
    };
  },
  mounted() {},
  created() {
    this.getData();
  },
  methods: {
    parseHtml(txt) {
      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(txt, "text/html");
      return htmlDoc;
    },

    getData() {
      // setTimeout(() => {
      //   this.loading = false;
      // }, 2000);
      var token = this.$cookies.get("token");
      $http
        .get("/stats", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.stats = response.data.data;
          this.loading = false;
        })
        .catch((error) => {})
        .finally(() => {
          setTimeout(() => {
            dialog.close();
          }, 500);
        });
    },
  },
  computed: {
    sections: function (e) {
      return [
        {
          label: "Rented",
          value: this.portfolio_stats.rented,
          color: "yellow",
        },
        { label: "Owned", value: this.portfolio_stats.owned, color: "green" },
        {
          label: "Invested",
          value: this.portfolio_stats.invested,
          color: "blue",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
