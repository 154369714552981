import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
const http = axios.create({ baseURL: API_URL + "/admin/api/v1", url: API_URL });
const http_auth = axios.create({
  baseURL: API_URL + "/auth/api/v1",
  url: API_URL,
});

// const http = axios.create({
//     baseURL: 'http://127.0.0.1:8000',
//     // baseURL: 'https://dev.fragvest.com',
//     timeout: 10000,
//     params: {}
// });

http.interceptors.request.use(
  function (req) {
    if (req.data === null || req.data === undefined) {
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      // dialog.errorTxt("Your session has expired");
    }
    return Promise.reject(error);
  }
);

export { http, http_auth };
